import React from 'react';
import { ProjectInfrastructureOwnerEnum } from '@lib/api-interface';
import { DataTable, HStack, Panel, ToggleButtons } from '@lib/ui-components';

import { useProjectBranchContext } from '@context';
import { useTranslation } from 'react-i18next';
import { InfrastructureCategorySelectOptionsFactory } from '../../../../../factories/infrastructure-category-select-options.factory';

// interface ImageDataRow {
//   imageUrl: string;
//   name: string;
// }

interface InfrastructureServicesProps {
  withMainTableTitle?: boolean;
}

export const InfrastructureServices = (props: InfrastructureServicesProps) => {
  const [tableReloadKey] = React.useState(1);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState<ImageDataRow>();
  const [activePage, setActivePage] = React.useState(0);

  const projectBranchViewModel = useProjectBranchContext();
  const { t } = useTranslation();

  // const imageData = [
  //   {
  //     imageUrl: '/containers/pojemniki_dwukołowe.png',
  //     name: 'POJEMNIKI DWUKOŁOWE (0.12; 0.24; 0.36)',
  //   },
  //   {
  //     imageUrl: '/containers/pojemniki_czterokołowe.png',
  //     name: 'POJEMNIKI CZTEROKOŁOWE (0.66; 0.77; 1.1)',
  //   },
  //   {
  //     imageUrl: '/containers/prasokontenery.png',
  //     name: 'PRASOKONTENERY (5-14m3; 16-24m3)',
  //   },
  //   {
  //     imageUrl: '/containers/kontenery_otwarte.png',
  //     name: 'KONTENERY OTWARTE (KP5-10m3; KP14-18m3; KP24-36m3)',
  //   },
  //   {
  //     imageUrl: '/containers/kontenery_zakryte.png',
  //     name: 'KONTENERY ZAKRYTE (KP5-10m3; KP14-18; KP24-36m3)',
  //   },
  //   {
  //     imageUrl: '/containers/kontenery.png',
  //     name: 'KONTENERY (2.5m3; 5m3)',
  //   },
  // ];

  // const handleRowClicked = (row: ImageDataRow) => {
  //   setSelectedImage(row);
  //   setIsModalOpen(true);
  // };

  return (
    <>
      <Panel header={props.withMainTableTitle ? t('projectInfrastructure') : undefined} borderType='raised'>
        <HStack align='center'>
          <ToggleButtons labels={[t('active'), t('inactive')]} activeIndex={activePage} onChange={(idx) => setActivePage(idx)} />
        </HStack>
        <DataTable
          excel={true}
          refreshKey={tableReloadKey}
          fetchPostUrl={`/api/client-infrastructure-services/by-project-branch/${projectBranchViewModel.projectBranchId}`}
          fetchFilters={{ active: activePage === 0 }}
          noFilters={true}
          columns={[
            {
              key: 'category',
              label: t('category'),
              type: 'enum',
              enumValues: InfrastructureCategorySelectOptionsFactory.getSelectOptionsWithAll(t),
            },
            {
              key: 'name',
              label: t('resourceName'),
              type: 'text',
            },
            {
              key: 'projectInfrastructureDescription',
              label: t('projectInfrastructureDescription'),
              type: 'text',
            },
            {
              key: 'estimatedMass',
              label: t('estimatedMass'),
              type: 'text',
            },
            {
              key: 'owner',
              label: t('owner'),
              type: 'enum',
              enumValues: [
                { value: ProjectInfrastructureOwnerEnum.CLIENT, label: t('client') },
                { value: ProjectInfrastructureOwnerEnum.SUBCONTRACTOR, label: t('subcontractor') },
                { value: ProjectInfrastructureOwnerEnum.ORGANIZATION, label: t('is') },
                { value: ProjectInfrastructureOwnerEnum.COMMUNE, label: t('commune') },
              ],
            },
            {
              key: 'currentSettings.infrastructureCount',
              label: t('number'),
              type: 'numeric',
            },
            {
              key: 'currentSettings.clientLease',
              label: t('leasePriceClient'),
              type: 'text',
            },
            {
              key: 'commune',
              label: t('commune'),
              type: 'boolean',
              align: 'center',
            },
            {
              key: 'activationDate',
              label: t('activationDate'),
              type: 'date',
            },
            {
              key: 'deactivationDate',
              label: t('deactivationDate'),
              type: 'date',
            },
          ]}
        />
        {/* <Typography sx={{ marginTop: 5 }} variant='body1' align='left' gutterBottom>*/}
        {/*  {t('containerTypeDescription')}*/}
        {/* </Typography>*/}
        {/* <DataTable*/}
        {/*  refreshKey={0}*/}
        {/*  data={imageData}*/}
        {/*  noFilters={true}*/}
        {/*  noPagination={true}*/}
        {/*  columns={[*/}
        {/*    {*/}
        {/*      key: 'name',*/}
        {/*      label: t('containerType'),*/}
        {/*      type: 'text',*/}
        {/*      align: 'center',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  onRowClicked={handleRowClicked}*/}
        {/* />*/}
        {/* <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>*/}
        {/*  <img src={selectedImage?.imageUrl} alt={selectedImage?.name} style={{ width: '60%', height: 'auto' }} />*/}
        {/* </Modal>*/}
      </Panel>
    </>
  );
};
