import React from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateCommandButton, DataTable, HStack, Snackbar, ToggleButtons } from '@lib/ui-components';
import { CollectionRequestStatus } from '../../../libs/api-interface/models/collection-request/collection-request-status.enum';

export const CollectionRequestsTab = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = React.useState(0);
  const [tableReloadKey, setTableReloadKey] = React.useState(1);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  function handleChangeActivePage(idx: number) {
    setActivePage(idx);
    setTableReloadKey(tableReloadKey + 1);
  }

  function renderCommentButton(row: any) {
    return (
      <AggregateCommandButton
        aggregateName={'CollectionRequestAggregate'}
        aggregateId={row.collectionRequestId}
        commandName={'comment'}
        buttonLabel={t('comment')}
        dialogTitle={t('edit')}
        variant='outlined'
        fields={[
          {
            field: 'comment',
            label: t('comment'),
            type: 'text',
            defaultValue: row.comment,
          },
        ]}
        onSaved={async () => setTableReloadKey(tableReloadKey + 1)}
      />
    );
  }

  const modelDef =
    activePage !== 0
      ? {
          modelName: 'collectionRequestViews',
        }
      : undefined;
  const fetchFilters =
    activePage !== 0
      ? {
          active: false,
        }
      : undefined;
  const fetchPostUrl = activePage === 0 ? '/api/collection-request/list/active' : undefined;

  return (
    <>
      <HStack align='center'>
        <ToggleButtons labels={[t('open'), t('history')]} activeIndex={activePage} onChange={handleChangeActivePage} />
      </HStack>
      <DataTable
        excel={true}
        refreshKey={tableReloadKey}
        modelDef={modelDef}
        fetchFilters={fetchFilters}
        fetchPostUrl={fetchPostUrl}
        contentMaxHeightAsAlmostOneScreen={true}
        initialSort={{ columnIndex: 0, ascending: false }}
        columns={[
          {
            key: 'creationDate',
            label: t('reportDate'),
            type: 'date',
            dateFormat: 'dd.MM.yyyy / HH:mm',
          },
          {
            key: 'collectionDate',
            label: t('pickupDate'),
            type: 'date',
          },
          {
            key: 'clientName',
            label: t('client'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'projectNumber',
            label: t('projectNumber'),
            type: 'text',
          },
          {
            key: 'branchName',
            label: t('branch'),
            type: 'text',
            minWidth: '8rem',
          },
          {
            key: 'branchRegistrationAddress.town',
            label: t('branchCity'),
            type: 'text',
          },
          {
            key: 'branchRegistrationAddress.street',
            label: t('branchStreet'),
            type: 'text',
            minWidth: '12rem',
          },
          {
            key: 'wasteCode',
            label: t('wasteCode'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'wasteName',
            label: t('wasteName'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'wasteDescription',
            label: t('wasteDescription'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'type',
            label: t('concerns'),
            type: 'enum',
            enumValues: [
              { value: 'PICKUP', label: t('collectionRequestType.PICKUP') },
              { value: 'SCHEDULE', label: t('collectionRequestType.SCHEDULE') },
              { value: 'NO_PICKUP', label: t('collectionRequestType.NO_PICKUP') },
              { value: 'EXCEED', label: t('collectionRequestType.EXCEED') },
            ],
            minWidth: '10rem',
          },
          {
            key: 'mass',
            label: t('massMg'),
            type: 'numeric',
          },
          {
            key: 'container',
            label: t('collectionMethod'),
            type: 'text',
          },
          {
            key: 'containers',
            label: t('numberOfContainers'),
            type: 'text',
          },
          {
            key: 'volume',
            label: t('volumeM3'),
            type: 'numeric',
          },
          {
            key: 'comment',
            label: t('comment'),
            type: 'text',
            minWidth: '12rem',
          },
          {
            key: 'status',
            label: t('noPickup'),
            type: 'boolean',
            align: 'center',
            expressionForBooleanType: (row) => row.status === CollectionRequestStatus.MISSED,
          },
          {
            key: 'missedPickupReportedBy',
            label: t('reported'),
            type: 'text',
            joinedColumns: [
              {
                key: 'missedPickupReportDate',
                label: '',
                type: 'date',
              },
            ],
          },
          {
            key: 'exceedContainers',
            label: t('collectionRequestType.EXCEED'),
            type: 'numeric',
          },
          {
            key: 'exceedReportedBy',
            label: t('reported'),
            type: 'text',
            joinedColumns: [
              {
                key: 'exceedReportDate',
                label: '',
                type: 'date',
              },
            ],
          },
          {
            key: 'createdBy',
            label: t('user'),
            type: 'text',
          },
          {
            key: 'emailsString',
            label: t('orderEmails'),
            type: 'text',
            minWidth: '15rem',
          },
          {
            key: 'subcontractor',
            label: t('subcontractor'),
            type: 'text',
          },
          {
            key: 'transporter',
            label: t('transportEntity'),
            type: 'text',
          },
        ]}
        rowOptions={
          activePage === 0
            ? [
                {
                  renderer: (row) => (
                    <AggregateCommandButton
                      aggregateName={'CollectionRequestAggregate'}
                      aggregateId={row.collectionRequestId}
                      commandName={'complete'}
                      buttonLabel={t('done')}
                      color='secondary'
                      variant='contained'
                      forceConfirmation={true}
                      onSaved={async () => {
                        setTableReloadKey(tableReloadKey + 1);
                        setShowSuccessMessage(true);
                      }}
                    />
                  ),
                },
                {
                  renderer: renderCommentButton,
                },
              ]
            : [
                {
                  renderer: renderCommentButton,
                },
              ]
        }
      />
      <Snackbar open={showSuccessMessage} message={t('collectionRequestDoneMessage')} severity='success' onClose={() => setShowSuccessMessage(false)} />
    </>
  );
};
