import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@lib/ui-components';
import { CollectionRequestPickup } from './collection-request-pickup';
import { CollectionRequestsTab } from './collection-requests-tab';
import { ContactForms } from './contact-form/contact-forms';

export const CollectionRequests = () => {
  const { t } = useTranslation();

  return (
    <>
      <Tabs
        panels={[
          { label: t('reportPickup'), component: <CollectionRequestPickup /> },
          { label: t('reportsV2.collectionRequests'), component: <CollectionRequestsTab /> },
          { label: t('contactForms'), component: <ContactForms /> },
        ]}
      />
    </>
  );
};
